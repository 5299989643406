// src/LandingPage.js
import React from "react";
import { Link } from "react-router-dom";
import "./LandingPage.css";

import video from "../static/video_info.mp4";

function LandingPage() {
  return (
    <div>
      {/* Sección principal */}
      <section className="landing-page">
        <div className="landing-content">
          <h1 className="landing-title">
            ACELERA TU INGRESO AL MERCADO LABORAL EN DISEÑO ESTRUCTURAL MEDIANTE
            APRENDIZAJE PRÁCTICO
          </h1>
          <p className="landing-description">Aprende, interactúa y resuelve.</p>
          <Link to="/register" className="landing-register-btn">
            Regístrate ahora
          </Link>
          <p className="landing-welcome-text">
            Bienvenido a
            <br />
            R&DC Academy
          </p>
        </div>
      </section>

      {/* Sección del video */}
      <section className="landing-video">
        <h1 className="landing-title more-info-title">
          CONOCE MÁS SOBRE R&DC Academy
        </h1>
        <div className="video-container">
          <video
            controls
            className="video"
            src={video}
            alt="Video informativo"
          />
        </div>
      </section>

      <section className="landing-info bg-style-1">
        <div className="info-main-text on-left">
          Aprende haciendo mediante nuestro modelo pedagógico del Aprendiz
          Cognitivo.
          <br />
          <br />
          Consolida tus conocimientos resolviendo proyectos reales con el
          acompañamiento de los expertos que ya recorrieron tu camino.
        </div>
      </section>

      <section className="landing-info bg-style-2">
        <div className="info-main-text on-right">
          Incorpora las tecnologías que están usando las empresas modernas y que
          aún no conocen las universidades.
        </div>
      </section>

      <section className="landing-info bg-style-3">
        <div className="info-main-text on-right">
          Interactúa con los verdaderos Stakeholders de un proyecto real.
          <br />
          <br />
          Construye tu portafolio certificado y recibe ofertas laborales.
        </div>
      </section>

      <section className="landing-info bg-style-4">
        <div className="info-main-text on-center">
          ¿Por qué aprender en R&DC Academy?
        </div>
      </section>

      <section className="landing-info bg-style-5">
        <div className="info-main-text on-right">
          La tecnología está transformando aceleradamente la manera de abordar
          el diseño estructural. Los desarrollos tecnológicos están siendo
          generados por empresas y para las empresas, sin embargo el actual
          sistema académico es un gran elefante lento para adaptarse a los
          cambios en la industria. Como resultado hay un fuerte desalineamiento
          entre la academia tradicional y las necesidades reales del sector
          productivo.
        </div>
      </section>

      <section className="landing-info bg-style-6">
        <div className="info-main-text on-left">
          ¿Cómo funciona nuestro modelo de aprendizaje?
        </div>
      </section>

      <section className="landing-cards">
        <div className="card">
          <h1>1</h1>
          <p>
            Creas tu perfil y R&DC Academy identifica tu nivel de conocimientos.
          </p>
        </div>
        <div className="card">
          <h1>2</h1>
          <p>
            R&DC academy identifica experiencias correspondientes a proyectos
            reales y justo en la frontera externa de tu zona de confort.
          </p>
        </div>
        <div className="card">
          <h1>3</h1>
          <p>
            R&DC Academy mapea tu experiencia en competencias y objetivos de
            aprendizaje y diseña una ruta de soporte apoyada por un tutor.
          </p>
        </div>
        <div className="card">
          <h1>4</h1>
          <p>
            Completa la experiencia interactuando con los Stakeholders y
            certifícala en tu portafolio.
          </p>
        </div>
      </section>

      <section className="landing-experience">
        <div className="experience-title">
          ¿En qué consiste una experiencia?
        </div>
        <div className="experience-step">Un proyecto</div>
        <div className="experience-step">Un conjunto de restricciones</div>
        <div className="experience-step">Un conjunto de entregables</div>
        <div className="experience-step">Un grupo de stakeholders</div>
      </section>
    </div>
  );
}

export default LandingPage;
