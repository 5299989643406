import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

// Amplify configuration
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
Amplify.configure(awsExports);


ReactDOM.createRoot(document.getElementById("root")).render(<App />);
