// src/Header.js
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../static/logo_academy.svg";
import "./Header.css";

function Header() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Verificar si la ruta actual es la de la LandingPage
  const isLandingPage = location.pathname === "/";

  return (
    <>
      <header className={`header ${isLandingPage ? "fixed-header" : "relative-header"}`}>
        <div className="flex justify-between items-center">
          {/* Logo */}
          <Link to="/" className="logo-container">
            <img src={logo} alt="R&DC Academy Logo" className="logo-img" />
          </Link>

          {/* Menu Toggle Button */}
          <button className="menu-button lg:hidden" onClick={toggleSidebar}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>

          {/* Navigation */}
          <nav className="nav hidden lg:flex">
            <Link to="/" className="nav-link">
              Inicio
            </Link>
            <a href="#learning-routes" className="nav-link">
              Rutas de aprendizaje
            </a>
            <a href="#community" className="nav-link">
              Comunidad
            </a>
            <Link to="/register" className="nav-link log-in-btn">
              Ingresa
            </Link>
            <Link to="/register" className="nav-link register-btn">
              Regístrate
            </Link>
          </nav>
        </div>
      </header>

      {/* Sidebar */}
      <div className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
        <button className="close-button" onClick={toggleSidebar}>
          &times;
        </button>
        <nav className="nav">
          <Link to="/" className="nav-link">
            Inicio
          </Link>
          <a href="#learning-routes" className="nav-link">
            Rutas de aprendizaje
          </a>
          <a href="#community" className="nav-link">
            Comunidad
          </a>
          <a href="#log-in" className="nav-link log-in-btn">
            Ingresa
          </a>
          <Link to="/register" className="nav-link register-btn">
            Regístrate
          </Link>
        </nav>
      </div>
    </>
  );
}

export default Header;
