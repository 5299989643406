// src/Register.js
import React from "react";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { I18n } from "@aws-amplify/core";
import "./Register.css";

// Traducción al español
I18n.putVocabularies({
  es: {
    "Sign In": "Iniciar sesión",
    "Sign in": "Iniciar sesión",
    "Sign Up": "Regístrate",
    "Sign Out": "Cerrar sesión",
    "Forgot your password?": "¿Olvidaste tu contraseña?",
    Password: "Contraseña",
    "Reset Password": "Recuperar acceso",
    "Send code": "Enviar código",
    Email: "Correo electrónico",
    "Change Password": "Cambiar contraseña",
    "New Password": "Nueva contraseña",
    "Confirm Password": "Confirmar contraseña",
    "Back to Sign In": "Volver a iniciar sesión",
    Submit: "Enviar",
    "Enter your Password": "Ingresa tu contraseña",
    "Please confirm your Password": "Confirma tu contraseña",
    "Enter your email": "Introduce tu correo electrónico",
    "Enter your phone number": "Introduce tu número de teléfono",
    "Create Account": "Crear cuenta",
    "Enter your new password": "Introduce tu nueva contraseña",
    "We Emailed You": "Te hemos enviado un correo",
    "Confirmation Code": "Código de confirmación",
    "Enter your code": "Ingresa el código",
    Confirm: "Confirmar código",
    "Resend Code": "Reenviar código",
    Confirming: "Confirmando",
  },
});

// Configurar el idioma a español
I18n.setLanguage("es");

function Register() {
  return (
    <div className="register-page">
      <Authenticator
        signUpAttributes={["email", "phone_number"]}
        formFields={{
          signUp: {
            password: {
              label: "Contraseña",
              placeholder: "Introduce tu contraseña",
              required: true,
            },
            phone_number: {
              label: "Celular",
              placeholder: "Introduce tu número de teléfono",
              required: true,
              dialCode: "+57",
              order: 3,
            },
            email: {
              label: "Correo electrónico",
              placeholder: "Introduce tu correo electrónico",
              required: true,
            },
            "custom:name": {
              label: "Nombre completo",
              placeholder: "Introduce tu nombre completo",
              required: true,
              type: "text",
              order: 1,
            },
            "custom:city": {
              label: "Ciudad",
              placeholder: "Introduce tu ciudad",
              required: true,
              type: "text",
              order: 2,
            },
          },
        }}
      >
        {({ signOut, user }) => (
          <div className="register-container">
            <h1 className="register-title">
              Bienvenido, {user ? user.signInDetails.loginId : "Usuario"}
            </h1>
            <p className="register-description">
              Ahora haces parte de R&DC Academy. Muy pronto tendrás acceso a
              toda la funcionalidad de nuestra aplicación. No te desconectes.
            </p>
            <button className="sign-out-btn" onClick={signOut}>
              Cerrar sesión
            </button>
          </div>
        )}
      </Authenticator>
    </div>
  );
}

export default Register;
